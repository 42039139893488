<template>
  <div style="height: 100vh" class="wwwscroll aaa">
    <div class="mySelf">
      <div class="right">
        <div class="R_header">
          <p>{{ nowheader }}</p>
        </div>
        <div class="detailBox wwwscroll">
          <Spin fix v-show="spinFlag">
            <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
            <div>Loading</div>
          </Spin>
          <div style="color: #000001" v-if="zuduiList.length == 0">
            暂时没有学生报名...
          </div>
          <div class="checkItem" v-for="(item, index) in zuduiList" :key="index">
            <div class="item_hea">
              <div class="title">姓名</div>
              <div class="detail">{{ item.name }}</div>
            </div>
            <div class="item_hea">
              <div class="title">手机号</div>
              <div class="detail">{{ item.phonenumber }}</div>
            </div>
            <div class="item_hea">
              <div class="title">身份</div>
              <div class="detail">
                {{ item.user_type }}
              </div>
            </div>
          </div>
        </div>
        <div class="btnBox">
          <div class="btn cacel" @click="proxy.$router.push('/')">返回</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, getCurrentInstance, onMounted } from "vue";
import { storage } from "llm-web-tool";
const { proxy } = getCurrentInstance();
import { debounce } from "@/utils/AntiShake";
import { findStudentByTeacher } from "@/api/ViewScreen/index_iscc.js";
let nowheader = ref("报名查询");
const CONFIG = window.CONFIG;
const spinFlag = ref(false);
let zuduiList = ref([]);
const getTeamList = () => {
  spinFlag.value = true;
  findStudentByTeacher().then((res) => {
    spinFlag.value = false;
    if (res.code == 200) {
      res.data.forEach((item) => {
        item.user_type = item.identityType == 1 ? '本科学生' : item.identityType == 2 ? '高职高专学生' : item.identityType == 3 ? '中职学生' : ''
      })
      zuduiList.value = res.data;
    } else {
      proxy.$Message.error(res.msg);
    }
  });
};

onMounted(() => {
  getTeamList();
});
</script>
<style>
.mySelf .ivu-select-placeholder {
  text-align: left !important;
}

.mySelf .ivu-select-selected-value {
  text-align: left !important;
}

.mySelf .box .ivu-select {
  position: relative;
}

.mySelf .ivu-checkbox-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
</style>
<style lang="less" scoped>
.aaa {
  background: url("../assets/bg.png") 50% 0 no-repeat;
  background-size: 100% 100%;
}

.mySelf {
  width: 960px;
  height: 100vh;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 32px 20px 0;
  display: flex;
  justify-content: space-between;
  background: rgba(255, 255, 255, 1);

  .right {
    width: 650px;
    display: flex;
    height: 100%;
    flex-direction: column;

    .R_header {
      padding: 0 5px;
      border-bottom: 1px solid #ededed;
      text-align: left;
      display: flex;
      height: 40px;
      line-height: 40px;
      font-size: 18px;
      font-weight: 600;

      p {
        width: auto;
        height: 40px;
        line-height: 40px;
        padding: 0 3px;
        border-bottom: 1px solid #0080ff;
      }
    }

    .detailBox {
      padding: 30px 20px 20px;
      border: 1px solid #eeeef0;
      flex: 1;
      position: relative;

      .checkItem {
        width: 100%;
        min-height: 80px;
        max-height: 250px;
        padding: 12px;
        border-bottom: 1px solid #eeeef0;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;

        .item_hea {
          display: flex;
          justify-content: space-between;

          height: 30px;
          line-height: 30px;
          margin-bottom: 10px;
          font-size: 16px;

          .title {
            width: 70px;
            color: #183139c9;
            font-weight: 600;
            text-align: right;
          }

          .detail {
            width: calc(100% - 90px);
            text-align: left;
            color: #1c0202c2;
          }
        }

        .jsicon {
          position: absolute;
          right: 10px;
          top: 10px;
          cursor: pointer;
          z-index: 1;
          width: 38px;
          height: 20px;
          background: #fb05053d;
          color: #f98e05a1;
          border-radius: 4px;
          font-size: 12px;
          text-align: center;
          line-height: 20px;
        }
      }
    }

    .btnBox {
      display: flex;
      justify-content: center;
      height: 50px;
      align-items: center;

      .btn {
        cursor: pointer;
        width: auto;
        padding: 0 18px;
        margin: 0 12px;
        line-height: 28px;
        height: 28px;
        text-align: center;
      }

      .cacel {
        border: 1px solid #c0c0c0;
      }

      .ok {
        border: 1px solid #0080ff;
        color: #fff;
        background: #0080ff;
      }

      .ok2 {
        border: 1px solid #0080ff;
        padding: 0 8px;
        color: #0080ff;
      }
    }
  }
}

// 手机端样式
@media screen and (max-width: 500px) {
  .aaa {
    background: none;
  }

  .mySelf {
    display: block !important;
    width: 100%;
    padding: 16px;

    .right {
      width: 100%;
      height: 100%;

      .detailBox {
        position: relative;

        .itemMyself {
          display: block;

          .title {
            text-align: left;
            color: #130b0b9c;
          }

          .box {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
