<!-- eslint-disable no-const-assign -->
<template>
  <div style="height: 100vh;overflow: hidden;" class="">
    <div class="loginHome">
      <div class="bannerOth">
        <img class="bannerImg" src="@/assets/banner.jpg" alt="" />
        <img class="bannerPhone bannerImg" src="@/assets/banner_phone.png" alt="" />
        <div class="header" v-if="stateObj.name != '' && stateObj.name != '御网杯'">
        </div>

        <div class="suBmitBtn" v-show="isSub == false">
          报名时间：{{ CONFIG.BaoMingTime[0] }}至{{ CONFIG.BaoMingTime[1] }}<br>请耐心等待
        </div>
      </div>
    </div>
    
  </div>
</template>
<script setup>

import { ref, reactive, getCurrentInstance, onMounted } from "vue";
import { storage } from "llm-web-tool";
import moment from "moment";
const { proxy } = getCurrentInstance();
let stateObj = reactive({
  tx: storage.session.get("showImg")
    ? storage.session.get("showImg")
    : "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F201911%2F21%2F20191121195046_fktqa.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1709256536&t=4125a729a5f1bbac8266b62f827975d2",
  name: storage.session.get("name") ? storage.session.get("name") : "御网杯",
  user_type: storage.session.get("user_type")
    ? storage.session.get("user_type")
    : "",
  phone: storage.session.get("phone") ? storage.session.get("phone") : "",
  id: storage.session.get("id") ? storage.session.get("id") : "",
});
//声明变量
const CONFIG = window.CONFIG;

//判断是否进入报名时间
const SubFlag = () => {
  let time = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
  if (time >= CONFIG.subTnTime[0] && time <= CONFIG.subTnTime[1]) {
    return true;
  } else {
    return false;
  }
};
const isSub = ref(SubFlag());
onMounted(() => {
  
});
</script>
<style lang="less">

// 手机端样式处理
@media screen and (max-width: 500px) {}
</style>
<style lang="less" scoped>
.container_1200 {
  width: 1200px;
  margin: 0 auto;
}

.loginHome {
  width: 100%;
  min-width: 1200px;
  box-sizing: border-box;
  .bannerOth {
    width: 100%;
    height: auto;
    min-height: 590px;
    position: relative;

    .bannerImg {
      width: 100%;
      height: 100vh;
      overflow: hidden;
    }

    .bannerPhone {
      display: none;
    }

    .header {
      width: 180px;
      height: 60px;
      position: absolute;
      top: 0;
      right: 8%;
      display: flex;
      // flex-direction: column;
      align-items: center;
      justify-content: space-around;

      .tx {
        width: 60px;
        height: 60px;
        border: 1px solid #ffffff;
        background: linear-gradient(0deg, #000000 0%, #ffffff 100%);
        border-radius: 50%;
        cursor: pointer;
        margin-bottom: 10px;
      }

      .name {
        width: auto;
        // margin-top: 10px;
        cursor: pointer;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: var(--coloraction);
        line-height: 30px;
        // background: #f0f0f0;
      }

      .logoOut {
        width: 80px;
        background: #fff;
        border-radius: 5px;
        color: red;
        cursor: pointer;
        position: absolute;
        font-size: 16px;
        top: 100px;
      }
    }

    // .header:hover {
    //   .logoOut {
    //     display: block;
    //   }
    // }
    .suBmitBtn {
      height: 70px;
      width: 100%;
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 15%;
      color: #ffffff;
      font-size: 30px;

      .btn {
        width: 200PX;
        height: 70PX;
        background: url("../assets/14.png");
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 15px;
        cursor: pointer;
        background-size: cover;
        background-position: center;

        span {
          width: 130px;
          height: 40px;
          font-size: 32px;
          font-family: Hiragino Sans GB;
          font-weight: normal;
          // color: #00eaff;
          color: #fff;
          line-height: 37px;

          // background: linear-gradient(
          //   0deg,
          //   #9db8d0 0%,
          //   #00e3fe 0%,
          //   #c7ffff 100%
          // );
          // -webkit-background-clip: text;
          // -webkit-text-fill-color: transparent;
        }
      }

      .btn:hover {
        // width: 237px;
        // height: 127px;
        background: url("../assets/13.png");
        background-size: cover;

        // box-shadow: 0px 0px 26px 1px #00D2FF;
        background-position: center;
      }

      .btn:hover span {
        span {
          width: 80px;
          height: 31px;
          font-size: 32px;
          font-family: Hiragino Sans GB;
          font-weight: normal;
          color: #00eaff;
          line-height: 37px;
        }
      }
    }
  }

  .bannerIner {
    width: 100%;
    background: url("../assets/bg.png") 50% 0 no-repeat;
    background-size: 100% 100%;
    height: auto;
    min-height: 590px;
    margin-top: -10px;

    .brief {
      padding-top: 55px;

      .headline {
        width: 551px;
        height: 84px;
        background: url("../assets/jianjie.png");
        margin: 0 auto 30px;
        background-size: 100% 100%;

        span {
          width: 125px;
          height: 31px;
          font-size: 32px;
          font-weight: bold;
          color: #00c6ff;
          line-height: 68px;
        }
      }

      .details {
        width: 1195px;
        min-height: 100px;
        background: url("../assets/jianjiebg.png");
        background-size: 100% 100%;
        padding: 60px;

        .title {
          font-size: 20px;
          color: #00ffea;
          line-height: 34px;
          width: 100%;
          text-align: center;
          margin-bottom: 12px;
        }

        .mark {
          text-indent: 40px;
          font-weight: 500;
          color: #00ffea;
          font-size: 16px;
          text-align: justify;
          line-height: 40px;
        }
      }

      .detailBox {
        width: 1195px;
        height: 550px;
        margin-top: 100px;
        padding: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .circle {
          width: 500px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 500px;
          position: relative;

          .step {
            width: 120px;
            height: 120px;
            border-radius: 50%;
            background: url("../assets/555.png");
            position: absolute;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            cursor: pointer;

            img {
              //   width: 54px;
              height: 54px;
            }

            p {
              width: 100px;
              text-align: center;
              height: 40px;
              font-size: 18px;
              font-weight: bold;
              color: #44f2ff;
              line-height: 40px;
            }

            .act {
              width: 188px;
              height: 188px;
              position: absolute;
              top: -28%;
              display: none;
            }
          }

          .step1 {
            left: 0;
            top: 35%;
          }

          .step2 {
            left: 29%;
            top: 9px;
          }

          .step3 {
            left: 71%;
            top: 96px;
          }

          .step4 {
            left: 66%;
            top: 314px;
          }

          .step5 {
            left: 25%;
            bottom: 14px;
          }

          //上面是五个的 下面是四个的
          // .step1 {
          //   left: 0;
          //   top: 35%;
          // }

          // .step2 {
          //   left: 37%;
          //   top: 9px;
          // }

          // .step3 {
          //   left: 74%;
          //   top: 35%;
          // }

          // .step5 {
          //   left: 37%;
          //   bottom: 0;
          // }

          .stepactive {
            .act {
              display: block;
            }
          }
        }

        .remark {
          width: 534px;
          display: flex;

          .remarks {
            width: 100%;
            height: auto;
            min-height: 100px;
            background: url("../assets/jianjiebg.png");
            background-size: 100% 100%;
            padding: 40px 30px;

            .re_title {
              font-size: 20px;
              color: #fffdc3;
              line-height: 34px;
              width: 100%;
              text-align: left;
              margin-bottom: 12px;
            }

            .re_detail {
              font-weight: 500;
              color: #00ffea;
              font-size: 16px;
              text-align: justify;
              line-height: 40px;
            }
          }
        }
      }

      .s_nBox {
        width: 1195px;
        min-height: 350px;
        margin-top: 36px;
        padding: 40px;

        .s_nItem {
          display: flex;
          justify-content: space-between;
          padding: 0 41px;

          .It {
            width: 235px;
            text-align: center;

            .title {
              height: 32px;
              line-height: 32px;
              font-size: 22px;
              color: #fffdc3;
            }

            .mark {
              min-height: 40px;
              line-height: 20px;
              font-size: 14px;
              color: #fffdc3;
            }
          }
        }
      }

      .s_detailBox {
        width: 1195px;
        min-height: 450px;
        padding: 40px;

        .s_item {
          width: 100%;
          display: flex;
          margin-bottom: 35px;

          .s_ttile {
            width: 169px;
            height: 47px;
            background: url("../assets/item.png");
            font-size: 18px;
            font-weight: 800;
            color: #fffdc3;
            line-height: 43px;
          }

          .s_mark {
            width: 867px;
            margin-left: 10px;
            font-weight: 500;
            color: #bdfdff;
            line-height: 40px;
            font-size: 18px;
            text-align: left;
          }
        }
      }

      .jx_tab {
        display: flex;
        color: #fff;
        font-size: 16px;
        justify-content: center;

        div {
          width: 124px;
          height: 34px;
          line-height: 34px;
          text-align: center;
          margin: 0 20px;
          cursor: pointer;
          background: url("../assets/JXB.png");
        }

        div:hover {
          background: url("../assets/JXA.png");
        }

        .active_class {
          background: url("../assets/JXA.png");
        }
      }

      .j_detailBox {
        width: 1195px;
        height: 325px;
        margin-top: 50px;
        padding: 61px;
        background: url("../assets/jianjiebg.png");
        background-size: 100% 100%;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

        .j_item {
          width: 300px;
          height: 214px;
          margin-bottom: 10px;
          cursor: pointer;

          .j_img {
            width: 149px;
            margin-bottom: 10px;
          }

          p {
            color: #fffdc3;
            font-size: 26px;
            font-family: Alibaba PuHuiTi;
            font-weight: 600;
            color: #fffdc3;
            line-height: 26px;
          }

          .o_img {
            margin-top: -40px;
            width: 0px;
          }
        }

        .j_itL {
          margin-left: 160px;
        }

        .j_itR {
          margin-right: 160px;
        }

        .j_item:hover .o_img {
          width: 147px;
        }

        .j_tips {
          width: 80%;
          margin: 0 auto;
          color: #ccc;
          text-align: left;
          font-size: 10px;
        }
      }

      .sx_detailBox {
        width: 1195px;
        min-height: 550px;
        padding: 61px;

        .sx_title {
          width: 159px;
          height: 44px;
          font-size: 22px;
          font-weight: bold;
          color: #fffdc3;
          line-height: 44px;
          text-align: left;
          padding-left: 20px;
          background: url("../assets/zzbg.png");
          background-size: 100% 100%;
          margin-bottom: 25px;
        }

        .sx_box {
          display: flex;
          flex-wrap: wrap;

          .b_item {
            width: auto;
            padding: 0 30px;
            height: 60px;
            line-height: 60px;
            font-weight: 400;
            color: #ffffff;
            background: url("../assets/sxzz_base.png");
            background-size: 100% 100%;
            margin-bottom: 30px;
            margin-right: 70px;
            font-size: 18px;
          }
        }
      }

      .lx_detailBox {
        width: 1195px;
        min-height: 300px;
        padding: 61px;

        img {
          width: 55px;
          height: 45px;
        }

        div {
          display: flex;
          flex-wrap: wrap;
          height: 45px;
          line-height: 45px;
          margin-bottom: 10px;
          color: #fff;
          font-size: 20px;
        }

        .lx_title {
          margin-right: 10px;
        }
      }
    }

    .fotter {
      height: 160px;
      line-height: 135px;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      // padding-bottom: 40px;
      justify-content: space-around;
      width: 1200px;
      margin: 0 auto;
      padding: 0 300px;
    }
  }
}

@media screen and (min-width: 500px) {

  .detailBox_phone,
  .s_nBox_img_phone {
    display: none;
  }
}

// 手机端样式处理
@media screen and (max-width: 500px) {
  .container_1200 {
    width: 100%;
    margin: 0 auto;
  }

  .loginHome {
    width: 100%;
    max-width: 500px;
    min-width: 0px;

    .bannerOth {
      min-height: 0px;

      .bannerImg {
        display: none;
        height: 100vh;
      }

      .bannerPhone {
        display: block;
        width: 100%;
      }

      .header {
        width: 135px;
        height: 50px;

        // right: 3%;
        .tx {
          width: 50px;
          height: 50px;
        }

        .name {
          font-size: 14px;
          line-height: 10px;
          width: 100%;
          // margin-top: 10px;
          cursor: pointer;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: var(--coloraction);
          line-height: 30px;
        }
      }

      .suBmitBtn {
        bottom: 15%;
        height: 50px;
        font-size: 14px;

        .btn {
          width: 70px;
          height: 24px;
          margin: 0 10px;

          span {
            width: 70px;
            height: 40px;
            font-size: 14px;
            line-height: 40px;
          }
        }

        .btn:hover {
          background: url("../assets/14.png");
          background-size: cover;
          background-position: center;
        }
      }
    }

    .bannerIner {
      margin-top: 0px;

      .brief {
        padding-top: 10px;

        .phoneHeadLine {
          display: none;
        }

        .headline {
          width: 200px;
          height: 30px;
          background: url("../assets/phone/jianjie.png");
          background-size: 100% 100%;
          margin: 0 auto 10px;

          span {
            font-size: 15px;
            line-height: 23px;
          }
        }

        .details {
          width: 99%;
          background: none;
          padding: 12px 24px;

          .title {
            font-size: 14px;
            line-height: 24px;
          }

          .mark {
            text-indent: 32px;
            font-size: 12px;
            line-height: 24px;
            color: #ffffff;
          }
        }

        //报名流程
        .detailBox,
        .s_nBox_img {
          display: none;
        }

        .detailBox_phone {
          padding-top: 12px;

          .bm_item {
            width: 100%;
            display: flex;
            min-height: 30px;
            line-height: 20px;
            margin: 0 auto;
            justify-content: space-between;
            margin-bottom: 20px;
            padding: 0 24px;

            img {
              width: 80%;
            }

            .box {
              //手机端的报名
              // width: calc(100% - 70px);
              width: 100%;
              text-align: left;

              .title {
                font-size: 14px;
                line-height: 18px;
                color: #fffdc3;
                margin-bottom: 5px;
                text-align: center;
              }

              .mark {
                font-size: 12px;
                line-height: 20px;
                color: #bdfdff;
                text-indent: 24px;
              }
            }
          }
        }

        // 赛程安排
        .s_nBox {
          width: 99%;
          padding: 12px;
          min-height: 0;
          margin-top: 12px;

          .s_nBox_img_phone {
            width: 80%;
          }

          .s_nItem {
            padding: 0 15px;

            .It {
              width: 100px;

              .title {
                font-size: 12px;
              }

              .mark {
                font-size: 9px;
              }
            }
          }
        }

        .s_detailBox {
          width: 100%;
          padding: 12px;

          .s_item {
            display: block;
            margin-bottom: 20px;

            .s_ttile {
              width: 100px;
              height: 29.5px;
              font-size: 12px;
              line-height: 28px;
              background-size: 100%;
              margin: 0 auto 9px;
            }

            .s_mark {
              width: 100%;
              margin-left: 0;
              line-height: 26px;
              font-size: 12px;
              text-indent: 28px;
            }
          }
        }

        //奖项设置
        .jx_tab {
          margin-top: 20px;

          div {
            zoom: 0.7;
          }
        }

        .j_detailBox {
          width: 100%;
          background: none;
          margin-top: 12px;
          padding: 12px;
          height: 140px !important;
          align-items: center;

          .j_item {
            width: 95px;
            height: 88px;
            margin-bottom: 10px;
            cursor: pointer;

            .j_img {
              width: auto;
              height: 60px;
              margin-bottom: 10px;
            }

            p {
              line-height: 26px;
              font-size: 12px;
            }

            .o_img {
              display: none;
            }
          }

          .j_itL {
            margin-left: 50px;
          }

          .j_itR {
            margin-right: 50px;
          }
        }

        //赛项组织
        .sx_detailBox {
          width: 100%;
          background: none;
          // margin-top: 12px;
          padding: 12px;
          min-height: 510px;

          .sx_title {
            width: 119px;
            height: 34px;
            line-height: 34px;
            font-size: 16px;
            margin: 15px auto;
          }

          .sx_box {
            .b_item {
              width: 100%;
              height: 40px;
              line-height: 40px;
              font-weight: 400;
              color: #ffffff;
              background-size: 100% 100%;
              margin-bottom: 10px;
              font-size: 14px;
              margin-right: 0px;
            }
          }
        }

        .lx_detailBox {
          width: 100%;
          padding: 12px;
          min-height: 150px;

          div {
            height: auto;
            line-height: 30px;
            font-size: 13px;
            min-height: 30px;

            img {
              width: 33px;
              height: 27px;
            }

            .lx_title {
              width: 100%;
            }

            .lx_mark {
              text-indent: 35px;
            }
          }
        }

        .lx_detailImg {
          width: 90%;
        }
      }

      .fotter {
        width: 100%;
        word-wrap: break-word;
        line-height: 50px;
        height: 105px;
        padding: 0 30px;
        display: block;
      }
    }
  }
}
</style>
