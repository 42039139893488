<template>
  <div class="notFonud wwwscroll">
    <div class="foundBox">
      <img src="../assets/404.png" alt="" />
      <div class="path"
        @click="
          $router.push({
            path: '/',
          })
        "
      >
        返回首页
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.notFonud {
  width: 100%;
  height: 100vh;
  .foundBox {
    width: 1440px;
    height: 100%;
    min-height: 900px;
    margin: 0 auto;
    position: relative;
    img {
      position: absolute;
      top: 20;
      left: 50%;
      margin-left: -507px;
    }
    .path{
        width: 100%;
        font-size: 32px;
        line-height: 32px;
        height: 32px;
        cursor: pointer;
        position: absolute;
        top: 600px;
        text-align: center;
    }
  }
}
</style>
