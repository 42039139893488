import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import { storage } from "llm-web-tool";
import login from '../views/login.vue'
import domyself from '../views/domyself.vue'

import cxStudent from '../views/cxStudent.vue' //查询学生名单 手机端
// import teamPhone from '../views/teamPhone.vue'
// import cxPhone from '../views/cxPhone.vue'
// import schoolPhone from '../views/schoolPhone.vue' //联络员查询 手机端
import loginPhone from '../views/loginPhone.vue' //登录 手机端
import registerPhone from '../views/registerPhone.vue' //注册 手机端
import logOutPassWord from '../views/logOutPassWord.vue' //忘记密码 手机端
import NotFound from '../views/404NoTFound.vue' //NotFound页
const routes = [{
        path: '/',
        name: 'login',
        component: login,
    },
    {
        path: '/domyself',
        name: 'domyself',
        component: domyself,
        beforeEnter: (to, from, next) => {
            if (storage.session.get('token')) {
                next()
            } else {
                next('/')
            }
        },
    },

    {
        path: '/cxStudent',
        name: 'cxStudent',
        component: cxStudent,
        beforeEnter: (to, from, next) => {
            if (storage.session.get('token')) {
                next()
            } else {
                next('/')
            }
        },
    },
    // {
    //     path: '/schoolPhone',
    //     name: 'schoolPhone',
    //     component: schoolPhone,
    //     beforeEnter: (to, from, next) => {
    //         if (storage.session.get('token')) {
    //             next()
    //         } else {
    //             next('/')
    //         }
    //     },
    // },
    // {
    //     path: '/teamPhone',
    //     name: 'teamPhone',
    //     component: teamPhone,
    //     beforeEnter: (to, from, next) => {
    //         if (storage.session.get('token')) {
    //             next()
    //         } else {
    //             next('/')
    //         }
    //     },
    // },
    // {
    //     path: '/cxPhone',
    //     name: 'cxPhone',
    //     component: cxPhone,
    //     beforeEnter: (to, from, next) => {
    //         if (storage.session.get('token')) {
    //             next()
    //         } else {
    //             next('/')
    //         }
    //     },
    // },
    {
        path: '/loginPhone',
        name: 'loginPhone',
        component: loginPhone,

    },
    {
        path: '/registerPhone',
        name: 'registerPhone',
        component: registerPhone,
    },
    {
        path: '/logOutPassWord',
        name: 'logOutPassWord',
        component: logOutPassWord,
    },
].concat([{
    path: '/:catchAll(.*)',
    name: 'not-found',
    component: NotFound,
    beforeEnter: (to, from, next) => {
        if (sessionStorage.getItem('token')) {
            next()
        } else {
            next('/')
        }
    },
}])

const router = createRouter({
    mode: 'hash',
    history: createWebHistory(process.env.BASE_URL),
    // history:createWebHashHistory(process.env.BASE_URL),
    routes
})

export default router