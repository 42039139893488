// import Cookies from "js-cookie";


import axios from "axios";
import {
    Modal,
    Message
} from 'view-ui-plus'

import {
    storage
} from "llm-web-tool";
let baseURL = process.env.VUE_APP_API_URL;
// 导出API前缀
export const BaseURL = baseURL; //线上模式 在主index页面
// 
const beforeSend = (options, onProcess) => {
    // 设置默认请求方法
    if (!options.param_token) { //param_token不传默认带token
        if (options.param_type) {
            options.headers = {
                "Content-Type": "application/json;charset=UTF-8",
                'Content-Security-Policy': "default-src 'self' 'unsafe-inline' 'unsafe-eval'; script-src 'self' 'unsafe-inline' 'unsafe-eval'; style-src 'self' 'unsafe-inline'; img-src 'self' data:; font-src 'self' data:; connect-src 'self'; media-src 'self'; object-src 'none'; frame-src 'none'; report-uri /__cspreport__/",
                'Authorization': 'Bearerw ' + storage.session.get('token'), //挂在token
                'X-Content-Type-Options': 'nosniff',
                'X-XSS-Protection': '1; mode=block'
            };
        } else {
            options.headers = {
                'Authorization': 'Bearerw ' + storage.session.get('token'), //挂在token
                'Content-Security-Policy': "default-src 'self' 'unsafe-i	nline' 'unsafe-eval'; script-src 'self' 'unsafe-inline' 'unsafe-eval'; style-src 'self' 'unsafe-inline'; img-src 'self' data:; font-src 'self' data:; connect-src 'self'; media-src 'self'; object-src 'none'; frame-src 'none'; report-uri /__cspreport__/",
                'X-Content-Type-Options': 'nosniff',
                'X-XSS-Protection': '1; mode=block'
            };
        }
    } else { //param_token传不带
        if (typeof options.headers == "undefined" && options.param_type) {

            options.headers = {
                "Content-Type": "application/json;charset=UTF-8",
                'Content-Security-Policy': "default-src 'self' 'unsafe-inline' 'unsafe-eval'; script-src 'self' 'unsafe-inline' 'unsafe-eval'; style-src 'self' 'unsafe-inline'; img-src 'self' data:; font-src 'self' data:; connect-src 'self'; media-src 'self'; object-src 'none'; frame-src 'none'; report-uri /__cspreport__/",
                'X-Content-Type-Options': 'nosniff',
                'X-XSS-Protection': '1; mode=block'
            };
        }
    }

    if (typeof options.method == "undefined") {
        options.method = "POST";
    } else {
        options.method = options.method;
    }
    // 监听文件上传进度
    if (onProcess) {
        if (onProcess instanceof Object) {
            for (let key in onProcess) {
                axiosConfig[key] = onProcess[key]
            }
        }

    }
    // 处理API的URL前缀
    Object.keys(options).forEach((key) => {
        options[key] = options[key];
        // 判断key 值里有url 并有http协议就自动加上请求前缀（服务器的域名)
        if (key == "url" && options[key].indexOf("http") !== 0) {
            options[key] = baseURL + options[key];
        }
    });

    // 处理授权问题
    if (typeof options.data == "undefined") options.data = {};
    // 如果是GET方式，将data载入params
    if (options.method == "GET") {
        options.method = "GET";
        options.url = options.url + "?"; //给地址后面加？用来拼接参数
        options.params = options.data;
    } else if (options.method == "GETry") {
        options.method = "GET";
        options.url = options.url + options.data;
    } else if (options.method == "PUT") {
        options.method = "PUT";

    } else if (options.method == "DELETE") {
        options.method = "DELETE";
        if (Object.keys(options.data).length != 0) { //判断是否有值
            options.url = options.url + options.data;
        }

    }
    if (!options.param_type && !options.param_typearr) {
        if (options.data) {
            let fd = new FormData();
            Object.keys(options.data).forEach((key) => {
                fd.append(key, options.data[key]);
            });
            options.data = fd;
        }
    } else if (options.param_typearr) {
        //穿文件数组
        if (options.data) {
            let fd = new FormData();
            if (!options.canshu) {
                let key = Object.keys(options.data); //获取到后台接受的key名

                for (let i = 0; i < options.data[key].length; i++) {
                    fd.append(key, options.data[key][i]);
                }
            } else {
                let key = Object.keys(options.data); //获取到后台接受的key名
                for (let i = 0; i < key.length; i++) {
                    if (
                        key[i] == "checkFile" ||
                        key[i] == "file" ||
                        key[i] == "checkFiles" ||
                        key[i] == "protocolFiles" ||
                        key[i] == "prosrFiles" ||
                        key[i] == "otherFiles" ||
                        key[i] == "quitFiles" ||
                        key[i] == "insiderFiles" ||
                        key[i] == "acceptFiles" ||
                        key[i] == "files"
                    ) {

                        for (let m = 0; m < options.data[key[i]].length; m++) {

                            fd.append(key[i], options.data[key[i]][m]);
                        }
                    }
                }
                Object.keys(options.data).forEach((key) => {
                    if (
                        key != "checkFile" ||
                        key != "file" ||
                        key != "checkFiles" ||
                        key != "protocolFiles" ||
                        key != "prosrFiles" ||
                        key != "otherFiles" ||
                        key != "quitFiles" ||
                        key != "insiderFiles" ||
                        key != "facceptFilesile" ||
                        key[i] == "files"
                    ) {
                        fd.append(key, options.data[key]);
                    }
                });
            }
            options.data = fd;
        }
    } else {
        // json对象
        options.data = JSON.stringify(options.data);
    }


    return options;
};

// 响应拦截器
const befroeResponse = (response) => {

    if (response.data.msg == '认证失败，无法访问系统资源' || response.data.msg == 'user.not.login') {
        Modal.warning({
            title: '登录超时',
            content: '请重新登录',
            onOk: () => {
                storage.session.remove('name') //用户id
                storage.session.remove('phone') //用户信息 
                storage.session.remove('token') //token 
                storage.session.remove('showImg') //token 
                storage.session.remove('user_type') //token 
                window.location.href = '/'
            },
        });
        return
    } else if (response.data.code == 500) {
        if (response.data.msg == '服务未找到') {

            return
        } else if (response.data.msg == '内部服务器错误') {

            return
        } else {
            if (response.data.msg) {
                if (response.data.msg.indexOf('org.springframework.jdbc.BadSqlGrammarException') != -1) {
                    return
                }
                if (response.data.msg.indexOf('级流控Permits:') != -1) {
                    response.data.msg = '请求验证码过于频繁，请稍后重试'
                    return response.data;
                }
            }
        }
    }
    return response.data;
};

// 错误处理器
const onRequestError = (e) => {
    return e;
};

// 导出请求方法
export const XHR = (options = {}) => {
    return new Promise((resolve, reject) => {
        axios(beforeSend(options))
            .then((r) => {
                resolve(befroeResponse(r));
            })
            .catch((e) => {
                reject(onRequestError(e));
            });
    });
};