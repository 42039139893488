<template>
  <router-view :key="reloadKey"/>
</template>
<script setup>
  import { ref } from 'vue'
  const reloadKey = Date.now()


  if (process.env.NODE_ENV == "production") {
      window.console.log = () => {}; 
      window.addEventListener(
        "keydown",
        function (e) {
          if (e.keyCode === 123 /* F12的键码 */) {
            e.preventDefault();
            e.stopPropagation();
            return false;
          }
        },
        true /* 在捕获阶段监听事件 */
      );
      window.addEventListener("contextmenu", function (e) {
        e.preventDefault();
      });
    }
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
