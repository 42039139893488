<template>
  <div style="height: 100vh" class="wwwscroll aaa">
    <div class="mySelfLogin">
      <div class="right">
        <div class="R_header">
          <p>{{ nowheader }}</p>
        </div>
        <div class="detailBox wwwscroll">
          <div class="form">
            <div class="item">
              <div class="title"><img src="@/assets/phone.png" alt="" /></div>
              <div class="box">
                <Input max-width="11" style="width: 100%" v-model="loginObj.phone" placeholder="请输入手机号" />
              </div>
            </div>
            <div class="item">
              <div class="title">
                <img src="@/assets/password.png" alt="" />
              </div>
              <div class="box">
                <Input max-width="20" style="width: 100%" v-model="loginObj.password" placeholder="请输入密码" password type="password" />
              </div>
            </div>
            <div class="itemB">
              <div class="title">
                <Input max-width="20" style="width: 100%" v-model="loginObj.yzm" placeholder="图形验证码" />
              </div>
              <div class="box" @click="getdlCaptcha">
                <img :src="dlyzm" alt="" />
              </div>
            </div>
            <div class="submit" @click="loginOk">登录</div>
            <div class="fotter">
              忘记密码?<span @click="towjmm">点击找回</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, getCurrentInstance, onMounted } from "vue";
import { storage } from "llm-web-tool";
const { proxy } = getCurrentInstance();
import { debounce } from "@/utils/AntiShake";
import {
  receptionCaptchaImage,//获取验证码
  receptionLogin,//登录接口
  findUserIntact,//查询信息是否完善
  findUserInfo,//查询用户信息
} from "@/api/ViewScreen/index_iscc";

let nowheader = ref("登录");
const CONFIG = window.CONFIG;

let loginObj = reactive({
  phone: "", //手机号
  password: "", //密码
  yzm: "",
});
const towjmm = () => {
  proxy.$router.push("./logOutPassWord");
};
const loginInit = () => {
  // eslint-disable-next-line no-const-assign
  loginObj = reactive({
    phone: "", //手机号
    password: "", //密码
    yzm: "",
  });
};
let dl_SIGN = ref(""); //图片验证码随机签名
let dlyzm = ref("");
const getdlCaptcha = debounce(() => {
  loginObj.yzm = ''
  dl_SIGN.value = "";
  receptionCaptchaImage().then(res => {
    if (res.code == 200) {
      dlyzm.value = "data:image/gif;base64," + res.data.img;
      dl_SIGN.value = res.data.uuid;
    }
  })
}, 100);

//登录执行函数
const loginOk = debounce(() => {
  if (loginObj.phone == "") {
    proxy.$Message.warning("请输入手机号");
    return;
  }
  if (loginObj.password == "" || loginObj.password.length < 6) {
    proxy.$Message.warning("请输入密码且密码不能少于6位");
    return;
  }
  if (loginObj.yzm == "") {
    proxy.$Message.warning("请输入图形验证码");
    return;
  }
  receptionLogin({
    phonenumber: loginObj.phone,
    password: loginObj.password,
    uuid: dl_SIGN.value,
    code: loginObj.yzm,
  }).then((res) => {
    if (res.code == 200) {
      proxy.$Message.success(res.msg);

      // 登录补充功能
      storage.session.set("token", res.data.token);
      findUserIntact().then((res1) => {
        if (res1.code == 200 && res1.data === 0) {
          findUserInfo().then((res2) => {
            if (res2.code == 200) {
              storage.session.set("name", res2.data.name);
              storage.session.set(
                "showImg",
                res2.data.avatarUrl
              );
              let str = ''
              if (res2.data.userType == 'sys_teacher') {
                str = '教师'
              } else {
                if (res2.data.identityType == 1) {
                  str = '本科学生'
                } else if (res2.data.identityType == 2) {
                  str = '高职高专学生'
                } else if (res2.data.identityType == 3) {
                  str = '中职学生'
                }
              }
              storage.session.set("user_type", str);
              storage.session.set("phone", res2.data.phonenumber);
              storage.session.set("id", res2.data.id);
              proxy.$router.push("/");
            }
          })
          return;
        } else {

          proxy.$router.push("./domyself");
        }
      });
    } else {
      getdlCaptcha();
      proxy.$Message.error(res.msg);
    }
  });
}, 500);

onMounted(() => {
  loginInit();
  getdlCaptcha();
});
</script>
<style>
.mySelfLogin .ivu-input {
  height: 40px !important;
}

.mySelfLogin .ivu-select-selected-value {
  text-align: left !important;
}

.mySelfLogin .box .ivu-select {
  position: relative;
}

.mySelfLogin .ivu-checkbox-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
</style>
<style lang="less" scoped>
.aaa {
  background: url("../assets/bg.png") 50% 0 no-repeat;
  background-size: 100% 100%;
}

.mySelfLogin {
  width: 960px;
  height: 100vh;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 32px 20px 0;
  display: flex;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0);

  .right {
    width: 650px;
    display: flex;
    height: 100%;
    flex-direction: column;

    .R_header {
      padding: 0 5px;
      border-bottom: 1px solid #ededed;
      text-align: left;
      display: flex;
      height: 40px;
      line-height: 40px;
      font-size: 18px;
      font-weight: 600;

      p {
        width: auto;
        height: 40px;
        line-height: 40px;
        padding: 0 3px;
        border-bottom: 1px solid #0080ff;
        color: #fff;
      }
    }

    .detailBox {
      padding: 30px 20px 20px;
      flex: 1;
      position: relative;
    }
  }
}

// 手机端样式
@media screen and (max-width: 500px) {
  .mySelfLogin {
    display: block !important;
    width: 100%;
    padding: 16px;

    .right {
      width: 100%;
      height: 100%;

      .detailBox {
        .form {
          width: 310px;
          margin: 30px auto;

          .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;

            .title {
              width: 20px;
              display: flex;
              align-items: center;

              img {
                width: 20px;
              }
            }

            .box {
              width: 275px;
            }
          }

          .itemB {
            padding-left: 35px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;

            .title {
              width: 140px;
            }

            .box {
              width: 120px;
              cursor: pointer;

              .btn {
                width: 120px;
                height: 32px;
                line-height: 32px;
                background: #44f2ff;
                border: 1px solid #44f2ff;
                border-radius: 2px;
                font-size: 14px;
                font-weight: 500;
                color: #010b17;
                text-align: center;
              }

              img {
                width: 120px;
                height: 40px;
              }
            }
          }
        }

        .submit {
          margin: 0 auto;
          width: 180px;
          height: 35px;
          background: #0556ab;
          border-radius: 17px;
          text-align: center;
          line-height: 35px;
          font-size: 18px;
          color: #fff;
          cursor: pointer;
        }

        .fotter {
          margin-top: 15px;
          text-align: right;
          color: #badde0;

          // padding-right: 60px;
          span {
            color: #3be4ff;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
