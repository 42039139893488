import {
    XHR,
    BaseURL
} from '../common/index_iscc.js'


// 模块API路径
export const ModelURL = "hr/";

// 请求拦截器
const beforeSend = (options) => {
    return options;
};

// 响应拦截器
const befroeResponse = (response) => {
    return response;
};

// 错误处理器
const onRequestError = (e) => {
    return e;
};

// 请求执行方法
const send = (options) => {
    return new Promise((resolve, reject) => {
        XHR(beforeSend(options))
            .then((r) => {
                resolve(befroeResponse(r));
            })
            .catch((e) => {
                reject(onRequestError(e));
            });
    });
};

// 生成图形验证码
export const receptionCaptchaImage = (data, options = {}) => {
    options.data = data;
    options.url = "/online/user/captchaImage";
    options.method = "GET";
    return send(options);
};
// 获取手机验证码
export const registerCaptchaSms = (data, options = {}) => {
    options.data = data;
    options.url = "/online/user/registerCaptchaSms";
    options.method = "GET";
    return send(options);
};
// 注册账号
export const userRegister = (data, options = {}) => {
    options.data = data;
    options.url = "/online/user/userRegister";
    options.method = "POST";
    options.param_type = "json";
    return send(options);
};
// 登录
export const receptionLogin = (data, options = {}) => {
    options.data = data;
    options.url = "/online/user/login";
    options.method = "POST";
    options.param_type = "json";
    return send(options);
};
// 重置密码
export const resetPassword = (data, options = {}) => {
    options.data = data;
    options.url = "/online/user/resetPassword";
    options.method = "POST";
    options.param_type = "json";
    return send(options);
};
// 查询用户信息是否完善
export const findUserIntact = (data, options = {}) => {
    options.data = data;
    options.url = "/online/user/findUserIntact";
    options.method = "GET";
    return send(options);
};
// 查询学校赛事专员
export const findCommissioner = (data, options = {}) => {
    options.data = data;
    options.url = "/online/user/findCommissioner";
    options.method = "GET";
    return send(options);
};
// 查询用户信息
export const findUserInfo = (data, options = {}) => {
    options.data = data;
    options.url = "/online/user/findUserInfo";
    options.method = "GET";
    return send(options);
};
// 修改用户信息
export const updateUser = (data, options = {}) => {
    options.data = data;
    options.url = "/online/user/updateUser";
    options.method = "POST";
    options.param_type = "json";
    return send(options);
};
// 查询省份列表
export const provinceDiceData = (data, options = {}) => {
    options.data = data;
    options.url = "/online/user/provinceDiceData";
    options.method = "GET";
    return send(options);
};
// 查询学校列表
export const getSchoolList = (data, options = {}) => {
    options.data = data;
    options.url = "/online/user/schoolList";
    options.method = "GET";
    return send(options);
};

// 上传头像
export const uploadAvatar = (data, options = {}) => {
    options.data = data;
    options.url = "/online/user/uploadAvatar";
    options.method = "POST";
    // options.param_type = "json";
    return send(options);
};
// 修改密码
export const updatePwd = (data, options = {}) => {
    options.data = data;
    options.url = "/online/user/updatePwd";
    options.method = "PUT";
    // options.param_type = "json";
    return send(options);
};

// 学生队组队
export const addTeamUp = (data, options = {}) => {
    options.data = data;
    options.url = "/online/user/addTeamUp";
    options.method = "POST";
    options.param_type = "json";
    return send(options);
};
//教师队组队
export const addTeamUpTeacher = (data, options = {}) => {
    options.data = data;
    options.url = "/online/user/addTeamUpTeacher";
    options.method = "POST";
    options.param_type = "json";
    return send(options);
};
// 查询可组队学校类型
export const findStudentTeamUType = (data, options = {}) => {
    options.data = data;
    options.url = "/online/user/findStudentTeamUType";
    options.method = "GET";
    return send(options);
};
// 查询学生报名名单 教师查询
export const findStudentByTeacher = (data, options = {}) => {
    options.data = data;
    options.url = "/online/user/findStudentByTeacher";
    options.method = "GET";
    return send(options);
};

//申请赛事专员
export const addCommissioner = (data, options = {}) => {
    options.data = data;
    options.url = "/online/user/addCommissioner";
    options.method = "POST";
    options.param_type = "json";
    return send(options);
};
// 查询赛段信息
export const stageDiceData = (data, options = {}) => {
    options.data = data;
    options.url = "/online/user/stageDiceData";
    options.method = "GET";
    return send(options);
};